<template>
    <v-container>
        <AdBTWR v-if="!user.isAdFree"/>

        <v-row justify="center">
            <v-col cols="12" xl="10">
                <h1 class="page-header font-weight-light mb-5">National and Regional GMRS Net System</h1>

                <v-tabs :vertical="$vuetify.breakpoint.xlOnly" center-active class="elevation-5 mb-5">
                    <v-tab class="justify-start">
                        <v-icon left>mdi-numeric-1</v-icon>
                        Introduction
                    </v-tab>
                    <v-tab class="justify-start">
                        <v-icon left>mdi-numeric-2</v-icon>
                        General Structure
                    </v-tab>
                    <v-tab class="justify-start">
                        <v-icon left>mdi-numeric-3</v-icon>
                        Main Groups
                    </v-tab>
                    <v-tab class="justify-start">
                        <v-icon left>mdi-numeric-4</v-icon>
                        Repeater Owner Information
                    </v-tab>
                    <v-tab class="justify-start">
                        <v-icon left>mdi-numeric-5</v-icon>
                        Net Control Documents
                    </v-tab>
                    <v-tab class="justify-start">
                        <v-icon left>mdi-numeric-6</v-icon>
                        History
                    </v-tab>

                    <v-tab-item>
                        <v-card flat>
                            <v-card-text class="subtitle-1">
                                <section id="introduction" class="mb-5">
                                    <h3 class="title">Introduction</h3>

                                    <p>The myGMRS National Net System is a series of general purpose radio nets that
                                        facilitate open
                                        discussion and traffic management, integrating net control operations
                                        simultaneously at both
                                        regional and national levels in order to efficiently manage communications
                                        between large groups
                                        of people over a wide geographic area. The system frequently fields well over
                                        100 check-ins from
                                        over 50 linked repeaters all over the country!</p>

                                    <p>Join us on any of the linked GMRS repeaters active on the <a
                                        href="https://network.mygmrs.com/map">myGMRS
                                        network</a>. The schedule of nets is as follows:</p>

                                    <NetSchedule/>

<!--                                    <v-sheet color="white" class="mb-5 mx-auto" max-width="512" rounded>-->
<!--                                        <v-img-->
<!--                                            src="@/assets/nets/system-schedule.png"-->
<!--                                            alt="National Net System Schedule"-->
<!--                                            max-width="512"-->
<!--                                        ></v-img>-->
<!--                                    </v-sheet>-->
                                </section>
                            </v-card-text>
                        </v-card>
                    </v-tab-item>
                    <v-tab-item>
                        <v-card flat>
                            <v-card-text class="subtitle-1">
                                <section id="generalstructure" class="mb-5">
                                    <h3 class="title">General Structure &amp; Checking In</h3>

                                    <p>The system is organized into several regional nets that collectively handle the
                                        check-in process
                                        for a larger national net. Regional nets operate independently from the national
                                        net; each is
                                        able to set their own script, and agenda.</p>

                                    <p>In doing so, we're able run an efficient parallel check-in process, have more
                                        relevant and
                                        pointed discussions at a regional level, and still facilitate the general
                                        discussions and
                                        exchange of messages at the larger national level.</p>

                                    <p>In order to participate in the national net, you must first check in with one of
                                        the regional
                                        nets in your area (see above schedule). Alternatively, you can also bypass the
                                        regional nets and
                                        check into the national net directly by signing up in advance online: myGMRS
                                        National Net –
                                        Online Check-in. The national net does not have an on-air check-in portion.</p>

                                    <p>The following map depicts which regional nets correspond to each state in the
                                        contiguous US:</p>

                                        <v-sheet color="white" class="mb-5 mx-auto" max-width="1024" rounded>
                                            <v-img
                                                src="@/assets/nets/regional-net-map.png"
                                                alt="Regional Net Configuration"
                                                max-width="1024"
                                            ></v-img>
                                        </v-sheet>

                                    <p>This approach forms a cohesive system that is unique to the myGMRS network, and
                                        is required to
                                        efficiently manage discussion between an otherwise very large group of people in
                                        a reasonable
                                        amount of time. In order to join the net, you must have access to one of the
                                        repeater systems on
                                        the myGMRS network.</p>

                                </section>
                            </v-card-text>
                        </v-card>
                    </v-tab-item>
                    <v-tab-item>
                        <v-card flat>
                            <v-card-text class="subtitle-1">
                                <section id="maingroups" class="mb-5">
                                    <h3 class="title">Main Groups</h3>

                                    <p>The following repeater groups have played a vital role in the success and growth
                                        of the net;
                                        please visit the relevant group in your area to find out more about each of
                                        their repeater
                                        systems and what can be done to support them:</p>

                                    <ol>
                                        <li><a href="https://tucsongmrs.org/">Tucson GMRS Association</a> (Coverage in
                                            Arizona)
                                        </li>
                                        <li><a href="http://gmrsnm.net/">GMRS New Mexico</a> (Coverage in New Mexico)
                                        </li>
                                        <li><a href="https://mwgmrs.com/">Midwest GMRS Repeater Network</a> (Coverage in
                                            Illinois,
                                            Wisconsin, Indiana, South Dakota)
                                        </li>
                                        <li><a href="https://mygmrs.com/networks?action=view&id=437">NC Linking
                                            Initiative</a> (Coverage
                                            in North Carolina, Virginia)
                                        </li>
                                        <li><a href="https://csragmrs.com/">CSRA GMRS Network</a> (Coverage in South
                                            Carolina, Georgia)
                                        </li>
                                        <li><a href="https://www.texasgmrs.net/">Texas GMRS Network</a> (Coverage in
                                            Texas)
                                        </li>
                                        <li>Other repeaters may also connect at times in states not listed here; you can
                                            find these on
                                            <a href="https://mygmrs.com/">myGMRS</a></li>
                                    </ol>
                                </section>
                            </v-card-text>
                        </v-card>
                    </v-tab-item>
                    <v-tab-item>
                        <v-card flat>
                            <v-card-text class="subtitle-1">
                                <section id="repeaterowners" class="mb-5">
                                    <h3 class="title">Information for Repeater Owners</h3>

                                    <p>The net system relies on the use of regional traffic hubs operated by various
                                        groups on the
                                        network. To partake in the net, your repeater will need to be linked to the
                                        corresponding hub in
                                        its area at the times given on the schedule above. The following is a map of
                                        regional hubs,
                                        color coded with their respective service areas:</p>

                                        <v-sheet color="white" class="mb-5 mx-auto" max-width="1024" rounded>
                                            <v-img
                                                src="@/assets/nets/national-net-map.png"
                                                alt="National Net Configuration"
                                                max-width="1024"
                                            ></v-img>
                                        </v-sheet>

                                    <p>The above shows the typical relationship between regional hubs during the
                                        national net. Node 100
                                        is the national traffic hub – no repeaters should directly connect to this node
                                        as it is meant
                                        for other regional hubs only.</p>

                                    <p>The following shows the typical relationship between regional hubs when the
                                        regional nets are
                                        active:</p>

                                        <v-sheet color="white" class="mb-5 mx-auto" max-width="1024" rounded>
                                            <v-img
                                                src="@/assets/nets/regional-net-map.png"
                                                alt="Regional Net Configuration"
                                                max-width="1024"
                                            ></v-img>
                                        </v-sheet>

                                    <p>Note that the regional hub for the associated service area never changes – for
                                        example; a
                                        repeater in Arizona will always connect to 176, in Georgia, you'd always connect
                                        to 174. The
                                        routing of traffic between regional hubs and/or national hubs is automated based
                                        on the net
                                        schedule given earlier on this page.</p>
                                </section>
                            </v-card-text>
                        </v-card>
                    </v-tab-item>
                    <v-tab-item>
                        <v-card flat>
                            <v-card-text class="subtitle-1">
                                <section id="netcontroldocs" class="mb-5">
                                    <h3 class="title">Net Control Documents</h3>

                                    <p>The following shared documents are used for net control operations:</p>

                                    <ol>
                                        <li>
                                            <a href="https://docs.google.com/spreadsheets/d/1R7RZbgs5vL9UXeb_Lwq1viIk9-FmIx_1NK98anCxDrc/edit?usp=sharing">Integrated
                                                Logging Worksheet</a></li>
                                        <li>
                                            <a href="https://docs.google.com/document/d/1FpbqI6ngF9lilp-aOpr7K8L4G6SezfpHhc4F42XiyNI/edit?usp=sharing">National
                                                GMRS Net Script</a></li>
                                        <li>
                                            <a href="https://docs.google.com/document/d/1RoK7DDXf80P99sjcPEuSEwdqXIhfvoUvJKstXLpnwHc/edit?usp=sharing">Regional
                                                GMRS Net &mdash; Eastern Script</a></li>
                                        <li>
                                            <a href="https://docs.google.com/document/d/1LXl9CaY4edMV3eYlJdkjVXVJkHQywKZOZ581jwFL_5E/edit?usp=sharing">Regional
                                                GMRS Net &mdash; Midwest Script</a></li>
                                        <li>
                                            <a href="https://docs.google.com/document/d/1aSyTF6VGcxw6zMeQlBdGPjJ-JLpsm5VPujiUglPPBBI/edit?usp=sharing">Regional
                                                GMRS Net &mdash; Southwest Script</a></li>
                                        <li><a href="https://forms.gle/2usAS3nJ8M1EcxoB6">Direct check-in form</a>
                                            (typically not used
                                            by net control)
                                        </li>
                                    </ol>
                                </section>
                            </v-card-text>
                        </v-card>
                    </v-tab-item>
                    <v-tab-item>
                        <v-card flat>
                            <v-card-text class="subtitle-1">
                                <section id="history" class="mb-5">
                                    <h3 class="title">History of the Net</h3>

                                    <p>The National GMRS Net originated as the Southwest GMRS net and was started as the
                                        result of a
                                        conversation between David, WQVS960 in Arizona, and Kevin, WRCX527 in New
                                        Mexico. A small net
                                        was kicked off, held every two-weeks between the New Mexico GMRS group and the
                                        Tucson GMRS
                                        Association. The Tucson GMRS Association had recently linked to the New Mexico
                                        GMRS group in an
                                        effort boost on-air activity in the Tucson area, which was generally silent.</p>

                                    <p>The first two nets were a success, with a modest 10 people checking in for the
                                        first net,
                                        followed by 23 check-ins for the second net.</p>

                                    <p>Shortly thereafter, a regular link was established adding in the Midwest GMRS
                                        group coordinated
                                        by Corey, WQVA593 in Wisconsin. Given the change in scope of the net, the
                                        decision was made to
                                        rename the net to the National GMRS Net now held weekly since there were now
                                        many states
                                        involved. Session 3 featured 27 check-ins, by session 8 this would balloon to
                                        over 100 check ins
                                        on a net that lasted over 3.5 hours, ran by then first time net-control operator
                                        Darren,
                                        WRCU527.</p>

                                    <p>With the rapidly growing net; interest grew from other groups on the myGMRS
                                        network. North
                                        Carolina joined by Session 9, New Jersey by Session 10, and Texas, South
                                        Carolina, and Georgia
                                        joined by Session 11. A record check-ins of 125 was reached by Session 15. The
                                        net continues to
                                        grow, the groups participating in the net have seen a steady surge in local
                                        interest as a result
                                        of the varied traffic heard by many on Sunday nights with regular check ins from
                                        over 21
                                        different states.</p>
                                </section>
                            </v-card-text>
                        </v-card>
                    </v-tab-item>
                </v-tabs>

                <iframe src="https://network.mygmrs.com/map" height="800" frameborder="0"></iframe>

            </v-col>
        </v-row>

<!--        <v-row justify="center">-->
<!--            <v-col cols="12" lg="10">-->
<!--                <h1 class="page-header font-weight-light mb-5">National and Regional GMRS Net System</h1>-->

<!--                <v-row justify="center">-->
<!--                    <v-col cols="12" sm="6" xl="4" order="2">-->
<!--                        <v-sheet color="white" class="pa-5" max-width="402">-->
<!--                            <v-img-->
<!--                                src="@/assets/mygmrs-network.png"-->
<!--                                max-width="402"-->
<!--                                contain-->
<!--                            ></v-img>-->
<!--                        </v-sheet>-->
<!--                    </v-col>-->
<!--                    <v-col cols="12" sm="6" xl="4" order="1">-->
<!--                        <section id="index" class="mb-5">-->
<!--                            <h3 class="title">Page Index</h3>-->

<!--                            <ol>-->
<!--                                <li><a href="#introduction">Introduction</a></li>-->
<!--                                <li><a href="#generalstructure">General Structure &amp; Checking In</a></li>-->
<!--                                <li><a href="#maingroups">Main Groups</a></li>-->
<!--                                <li><a href="#repeaterowners">Information for Repeater Owners</a></li>-->
<!--                                <li><a href="#netcontroldocs">Net Control Documents</a></li>-->
<!--                                <li><a href="#history">History of the Net</a></li>-->
<!--                            </ol>-->
<!--                        </section>-->
<!--                    </v-col>-->
<!--                </v-row>-->

                <!--                <section id="introduction" class="mb-5">-->
                <!--                    <h3 class="title">Introduction</h3>-->

                <!--                    <p>The myGMRS National Net System is a series of general purpose radio nets that facilitate open-->
                <!--                        discussion and traffic management, integrating net control operations simultaneously at both-->
                <!--                        regional and national levels in order to efficiently manage communications between large groups-->
                <!--                        of people over a wide geographic area. The system frequently fields well over 100 check-ins from-->
                <!--                        over 50 linked repeaters all over the country!</p>-->

                <!--                    <p>Join us on any of the linked GMRS repeaters active on the <a href="https://network.mygmrs.com/map">myGMRS-->
                <!--                        network</a>. The schedule of nets is as follows:</p>-->

                <!--                    <v-img-->
                <!--                        src="https://tucsongmrs.org/wp-content/uploads/2020/05/GMRS-Net-Schedule-1-1024x264.png"-->
                <!--                        alt="National Net System Schedule"-->
                <!--                        max-width="1024"-->
                <!--                        class="mx-auto"-->
                <!--                    ></v-img>-->

                <!--                </section>-->

                <!--                <section id="generalstructure" class="mb-5">-->
                <!--                    <h3>General Structure &amp; Checking In</h3>-->

                <!--                    <p>The system is organized into several regional nets that collectively handle the check-in process-->
                <!--                        for a larger national net. Regional nets operate independently from the national net; each is-->
                <!--                        able to set their own script, and agenda.</p>-->

                <!--                    <p>In doing so, we're able run an efficient parallel check-in process, have more relevant and-->
                <!--                        pointed discussions at a regional level, and still facilitate the general discussions and-->
                <!--                        exchange of messages at the larger national level.</p>-->

                <!--                    <p>In order to participate in the national net, you must first check in with one of the regional-->
                <!--                        nets in your area (see above schedule). Alternatively, you can also bypass the regional nets and-->
                <!--                        check into the national net directly by signing up in advance online: myGMRS National Net –-->
                <!--                        Online Check-in. The national net does not have an on-air check-in portion.</p>-->

                <!--                    <p>The following map depicts which regional nets correspond to each state in the contiguous US:</p>-->

                <!--                    <v-img-->
                <!--                        src="https://tucsongmrs.org/wp-content/uploads/2020/05/myGMRS-Hub-Configuration-02-1024x485.png"-->
                <!--                        alt="Regional Net Configuration"-->
                <!--                        max-width="1024"-->
                <!--                        class="mx-auto mb-5"-->
                <!--                    ></v-img>-->

                <!--                    <p>This approach forms a cohesive system that is unique to the myGMRS network, and is required to-->
                <!--                        efficiently manage discussion between an otherwise very large group of people in a reasonable-->
                <!--                        amount of time. In order to join the net, you must have access to one of the repeater systems on-->
                <!--                        the myGMRS network.</p>-->

                <!--                </section>-->

                <!--                <div class="text-center mb-5">-->
                <!--                    <InArticleAdsense-->
                <!--                        data-ad-client="ca-pub-2391363374702677"-->
                <!--                        data-ad-slot="4051888556"-->
                <!--                        v-if="!user.isAdFree"-->
                <!--                    >-->
                <!--                    </InArticleAdsense>-->
                <!--                </div>-->

                <!--                <section id="maingroups" class="mb-5">-->
                <!--                    <h3>Main Groups</h3>-->

                <!--                    <p>The following repeater groups have played a vital role in the success and growth of the net;-->
                <!--                        please visit the relevant group in your area to find out more about each of their repeater-->
                <!--                        systems and what can be done to support them:</p>-->

                <!--                    <ol>-->
                <!--                        <li><a href="https://tucsongmrs.org/">Tucson GMRS Association</a> (Coverage in Arizona)</li>-->
                <!--                        <li><a href="http://gmrsnm.net/">GMRS New Mexico</a> (Coverage in New Mexico)</li>-->
                <!--                        <li><a href="https://mwgmrs.com/">Midwest GMRS Repeater Network</a> (Coverage in Illinois,-->
                <!--                            Wisconsin, Indiana, South Dakota)-->
                <!--                        </li>-->
                <!--                        <li><a href="https://mygmrs.com/networks?action=view&id=437">NC Linking Initiative</a> (Coverage-->
                <!--                            in North Carolina, Virginia)-->
                <!--                        </li>-->
                <!--                        <li><a href="https://csragmrs.com/">CSRA GMRS Network</a> (Coverage in South Carolina, Georgia)-->
                <!--                        </li>-->
                <!--                        <li><a href="https://www.texasgmrs.net/">Texas GMRS Network</a> (Coverage in Texas)</li>-->
                <!--                        <li>Other repeaters may also connect at times in states not listed here; you can find these on-->
                <!--                            <a href="https://mygmrs.com/">myGMRS</a></li>-->
                <!--                    </ol>-->
                <!--                </section>-->


                <!--                <section id="repeaterowners" class="mb-5">-->
                <!--                    <h3>Information for Repeater Owners</h3>-->

                <!--                    <p>The net system relies on the use of regional traffic hubs operated by various groups on the-->
                <!--                        network. To partake in the net, your repeater will need to be linked to the corresponding hub in-->
                <!--                        its area at the times given on the schedule above. The following is a map of regional hubs,-->
                <!--                        color coded with their respective service areas:</p>-->

                <!--                    <v-img-->
                <!--                        src="https://tucsongmrs.org/wp-content/uploads/2020/05/myGMRS-network-maps-01-1-1024x485.png"-->
                <!--                        alt="National Net Configuration"-->
                <!--                        max-width="1024"-->
                <!--                        class="mx-auto mb-5"-->
                <!--                    ></v-img>-->

                <!--                    <p>The above shows the typical relationship between regional hubs during the national net. Node 100-->
                <!--                        is the national traffic hub – no repeaters should directly connect to this node as it is meant-->
                <!--                        for other regional hubs only.</p>-->

                <!--                    <p>The following shows the typical relationship between regional hubs when the regional nets are-->
                <!--                        active:</p>-->

                <!--                    <v-img-->
                <!--                        src="https://tucsongmrs.org/wp-content/uploads/2020/05/myGMRS-Hub-Configuration-02-1024x485.png"-->
                <!--                        alt="Regional Net Configuration"-->
                <!--                        max-width="1024"-->
                <!--                        class="mx-auto mb-5"-->
                <!--                    ></v-img>-->

                <!--                    <p>Note that the regional hub for the associated service area never changes – for example; a-->
                <!--                        repeater in Arizona will always connect to 176, in Georgia, you'd always connect to 174. The-->
                <!--                        routing of traffic between regional hubs and/or national hubs is automated based on the net-->
                <!--                        schedule given earlier on this page.</p>-->
                <!--                </section>-->


                <!--                <section id="netcontroldocs" class="mb-5">-->
                <!--                    <h3>Net Control Documents</h3>-->

                <!--                    <p>The following shared documents are used for net control operations:</p>-->

                <!--                    <ol>-->
                <!--                        <li>-->
                <!--                            <a href="https://docs.google.com/spreadsheets/d/1R7RZbgs5vL9UXeb_Lwq1viIk9-FmIx_1NK98anCxDrc/edit?usp=sharing">Integrated-->
                <!--                                Logging Worksheet</a></li>-->
                <!--                        <li>-->
                <!--                            <a href="https://docs.google.com/document/d/1FpbqI6ngF9lilp-aOpr7K8L4G6SezfpHhc4F42XiyNI/edit?usp=sharing">National-->
                <!--                                GMRS Net Script</a></li>-->
                <!--                        <li>-->
                <!--                            <a href="https://docs.google.com/document/d/1RoK7DDXf80P99sjcPEuSEwdqXIhfvoUvJKstXLpnwHc/edit?usp=sharing">Regional-->
                <!--                                GMRS Net &mdash; Eastern Script</a></li>-->
                <!--                        <li>-->
                <!--                            <a href="https://docs.google.com/document/d/1LXl9CaY4edMV3eYlJdkjVXVJkHQywKZOZ581jwFL_5E/edit?usp=sharing">Regional-->
                <!--                                GMRS Net &mdash; Midwest Script</a></li>-->
                <!--                        <li>-->
                <!--                            <a href="https://docs.google.com/document/d/1aSyTF6VGcxw6zMeQlBdGPjJ-JLpsm5VPujiUglPPBBI/edit?usp=sharing">Regional-->
                <!--                                GMRS Net &mdash; Southwest Script</a></li>-->
                <!--                        <li><a href="https://forms.gle/2usAS3nJ8M1EcxoB6">Direct check-in form</a> (typically not used-->
                <!--                            by net control)-->
                <!--                        </li>-->
                <!--                    </ol>-->

                <!--                </section>-->

                <!--                <div class="text-center mb-5">-->
                <!--                    <InArticleAdsense-->
                <!--                        data-ad-client="ca-pub-2391363374702677"-->
                <!--                        data-ad-slot="4051888556"-->
                <!--                        v-if="!user.isAdFree"-->
                <!--                    >-->
                <!--                    </InArticleAdsense>-->
                <!--                </div>-->

                <!--                <section id="history" class="mb-5">-->
                <!--                    <h3>History of the Net</h3>-->

                <!--                    <p>The National GMRS Net originated as the Southwest GMRS net and was started as the result of a-->
                <!--                        conversation between David, WQVS960 in Arizona, and Kevin, WRCX527 in New Mexico. A small net-->
                <!--                        was kicked off, held every two-weeks between the New Mexico GMRS group and the Tucson GMRS-->
                <!--                        Association. The Tucson GMRS Association had recently linked to the New Mexico GMRS group in an-->
                <!--                        effort boost on-air activity in the Tucson area, which was generally silent.</p>-->

                <!--                    <p>The first two nets were a success, with a modest 10 people checking in for the first net,-->
                <!--                        followed by 23 check-ins for the second net.</p>-->

                <!--                    <p>Shortly thereafter, a regular link was established adding in the Midwest GMRS group coordinated-->
                <!--                        by Corey, WQVA593 in Wisconsin. Given the change in scope of the net, the decision was made to-->
                <!--                        rename the net to the National GMRS Net now held weekly since there were now many states-->
                <!--                        involved. Session 3 featured 27 check-ins, by session 8 this would balloon to over 100 check ins-->
                <!--                        on a net that lasted over 3.5 hours, ran by then first time net-control operator Darren,-->
                <!--                        WRCU527.</p>-->

                <!--                    <p>With the rapidly growing net; interest grew from other groups on the myGMRS network. North-->
                <!--                        Carolina joined by Session 9, New Jersey by Session 10, and Texas, South Carolina, and Georgia-->
                <!--                        joined by Session 11. A record check-ins of 125 was reached by Session 15. The net continues to-->
                <!--                        grow, the groups participating in the net have seen a steady surge in local interest as a result-->
                <!--                        of the varied traffic heard by many on Sunday nights with regular check ins from over 21-->
                <!--                        different states.</p>-->
                <!--                </section>-->

<!--            </v-col>-->
<!--        </v-row>-->
    </v-container>
</template>

<script>
    import AdBTWR from '@/components/ads/BTWR.vue';
    import NetSchedule from '@/components/Nets/NetSchedule.vue';
    import {useTitle} from '@vueuse/core';

    export default {
        props: ['user'],

        components: {AdBTWR, NetSchedule},

        mounted(){
            useTitle('GMRS Nets - myGMRS.com');
        }
    }
</script>
